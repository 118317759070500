import { defineStore } from 'pinia'
import * as Sentry from '@sentry/browser'
import accountService from '@/services/account'
import { useAuthStore } from './auth'
import defineAbilityFor from '@/ability'

export const useUserStore = defineStore('user', {
  state: () => ({
    name: '',
    email: '',
    id: null,
    organisations: [],
    person_id: null,
    roles: [],
    settings: {
      eventFilter: {
        orgs: [],
      },
      participantSorting: 'date',
      nameDisplay: 'first-last',
      eventDisplay: 'list',
    },
  }),
  actions: {
    fetchUser() {
      return accountService.getMe()
        .then(me => {
          Object.assign(this, me)
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: me.id,
              email: me.email,
              username: me.name,
            })
          })
          useAuthStore().ability.update(defineAbilityFor(me))
          return me
        })
    },
    setEventFilter(filters) {
      this.settings = { ...this.settings, eventFilter: filters }
    },
    setConfig({ key, value }) {
      this.settings = { ...this.settings, [key]: value }
    },
  },
  persist: true,
})
