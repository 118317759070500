<template>
  <v-expansion-panels accordion flat>
    <template v-for="item in items">
      <!-- Month Header -->
      <li
        v-if="item.title"
        :key="item.id"
        :id="item.id"
        class="eep-month-header py-2"
      >
        {{ item.title }}
      </li>
      <!-- Event -->
      <event-list-item v-else :key="item.event.id" :event="item.event" />
    </template>
    <slot name="after" />
  </v-expansion-panels>
</template>

<script>
import { DateOnly } from '@/utils/dates'
import EventListItem from './EventListItem'

export default {
  name: 'EventExpansionPanel',
  components: { EventListItem },
  props: {
    events: {
      type: Array,
      required: true,
    },
    monthHeaders: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      if (!this.monthHeaders) {
        return this.events.map(e => { return { event: e } })
      }
      // Build the list of months and events
      var lst = []
      var startDate = DateOnly.fromString(this.events[0].start_date)
      const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      lst.push({ title: months[startDate.month - 1], id: 'm' + startDate.month })
      for (const event of this.events) {
        const thisDate = DateOnly.fromString(event.start_date)
        if (startDate.month < thisDate.month || startDate.year < thisDate.year) {
          lst.push({ title: months[thisDate.month - 1], id: 'm' + thisDate.month })
          startDate = thisDate
        }
        lst.push({ event: event })
      }
      return lst
    },
  },
  methods: {
    num_participants(event) {
      const memberCount = event.participants ? event.participants.length : 0
      const guestCount = event.guests ? event.guests.length : 0
      return memberCount + guestCount
    },
  },
}
</script>

<style lang="scss">
li.eep-month-header {
  width: 100%;
  padding-left: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-weight: 500;
}

li.v-expansion-panel__container--active + li.eep-month-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  transition: border-top 0s ease-out 0.2s;
}

.event-expansion-panel {
  .v-expansion-panel__header {
    height: 60px;
  }

  .eep-text {
    overflow: hidden;
    justify-content: center;
    width: 1px;
    span {
      padding: 2px 6px 2px 14px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.eep-title {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }

  .eep-icon {
    flex-grow: 0;
    align-self: center;
    white-space: nowrap;

    .btn--icon {
      margin: 4px;
    }
  }
}
</style>
