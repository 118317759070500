import axios from '@/plugins/axios'

const PER_PAGE = 100
const END_POINT = '/api/v1/users'

function list() {
  var items = []

  function fetch(page = 1) {
    console.log('Fetching accounts page ' + page)
    return axios.get(END_POINT, { params: { page, per_page: PER_PAGE } })
      .then((response) => {
        items = [...items, ...response.data.items]
        if (response.data.page < response.data.pages) {
          return fetch(response.data.page + 1)
        } else {
          return items
        }
      })
  }

  return fetch()
}

function get(id) {
  return axios.get(`${END_POINT}/${id}`)
    .then((response) => {
      return response.data
    })
}

function getMe() {
  console.log('Fetching account of logged in user')
  return axios.get(`${END_POINT}/me`)
    .then(response => {
      return response.data
    })
}

function create(account) {
  return axios.post(END_POINT, account)
    .then((response) => {
      return response.data
    })
}

function update(account) {
  return axios.put(`${END_POINT}/${account.id}`, account)
    .then((response) => {
      return response.data
    })
}

function remove(account) {
  return axios.delete(`${END_POINT}/${account.id}`)
    .then(response => {
      return account
    })
}

export default {
  list,
  get,
  getMe,
  create,
  update,
  remove,
}
